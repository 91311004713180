import { Notify, type QNotifyCreateOptions } from "quasar";

Notify.setDefaults({
  position: "top-right",
  classes: "notification",
  badgeClass: "notification--badge",
  timeout: 3000,
});

Notify.registerType("system", {
  html: true,
  classes: "notification--system",
  actions: [
    {
      icon: "close",
      size: "small",
      color: "black",
      round: true,
      handler: () => {},
    },
  ],
});
Notify.registerType("error", {
  html: true,
  classes: "notification--error",
  actions: [
    {
      icon: "close",
      size: "small",
      color: "black",
      round: true,
      handler: () => {},
    },
  ],
});

export class Notification {
  args: QNotifyCreateOptions | undefined;
  constructor(args: QNotifyCreateOptions | undefined) {
    this.args = args;
  }

  createNewToast(message?: string) {
    return Notify.create({
      ...this.args,
      message: this.args?.message ? this.args.message : message,
    });
  }
}
